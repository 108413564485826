import React, { useState, useRef } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
//import { BrowserRouter as Router } from "react-router-dom"
import { logo } from './img'
import Chart from './Chart'
import ReCAPTCHA from "react-google-recaptcha"
import Axios from 'axios'


export default function Landing () {

    const submit_msg_good = 'Thank you. Please check your email to proceed';
    const submit_msg_bad  = 'Please confirm that you are not a robot';
    const submit_msg_email  = 'Please enter a valid email address';
    const [submitState, setSubmitState] = useState(0);
    const [textCount, setTextCount] = useState(0);
    const captchaRef = useRef(null);
    const emailRef = useRef(null);
    const textRef = useRef(null);

    const query_form_endpoint = process.env.REACT_APP_QUERY_FORM_ENDPT;
    const recaptcha_site_key = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
 
    const recalculate = e => {
        setTextCount(e.target.value.length);
    };

    const handleSubmit = e =>{
        e.preventDefault();
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        if (!token) {
            //console.log('no token');
            setSubmitState(2);
        } else {
            //console.log('yes token');
            console.log('email:' + emailRef.current.value);
            const emailContact = emailRef.current.value;
            console.log('emailContact:' + emailContact);
            let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if ( re.test(emailContact) ) {
                const textContact = textRef.current.value;
                console.log('textContact:' + textContact);

                Axios.post(query_form_endpoint, {
                    "email": emailContact,
                    "text": textContact,
                    "token": token
                   })
                  .then(function (response) {
                    console.log(response);
                    setSubmitState(1);
                  })
                  .catch(function (error) {
                    console.log(error);
                  });

            } else {
                setSubmitState(3);
            }
        }

        setTimeout(() => {
            setSubmitState(0);
        }, 6000);
    }

    const SubmitMessage = () => {
        switch(submitState) {
            case 1:
                return (<p className="tiny-green">{submit_msg_good}</p>);
            case 2:
                return (<p className="tiny-red">{submit_msg_bad}</p>)
            case 3:
                return (<p className="tiny-red">{submit_msg_email}</p>)
            default:
              return (null);
        }
    }

    return (
        
        <div className="simple-bkgd">
            <Container fluid>
                <Row>
                    <Col lg={10} sm={12} xs={12}><div style={{textAlign:'center', marginTop:'0px', paddingTop:'0px'}}><img src={logo} alt="logo" width="30%" height="30%"/></div></Col>
                    {/* <Col xs={2}><div style={{textAlign:'right', paddingTop:'20px'}}><img src={login} alt="login" width="59" height="33"/></div></Col> */}
                    <Col lg={2} sm={0} xs={0}><div style={{textAlign:'right', paddingTop:'20px'}}></div></Col>
                </Row>
                <Row>
                    <Col lg={5} sm={12} xs={12}><div style={{textAlign:'left', paddingTop:'2em', paddingLeft:'2em'}}>
                        <p>We are a group of seasoned Software Professionals with many years of industrial experience.  We offer end-to-end services in 
                            Technology Consulting, Solution Designs, Management, Implementations and Operational supports.</p> 
                        <p>Codingmachine offers nimble ways to augment the technical capabilities of your business.  Our unique and personalized approaches 
                            allow our customers to stay engaged and informed throughout and beyond project life cycles.</p>
                        </div>
                        <div style={{textAlign:'left', paddingLeft:'2em'}}>
                            <p>Feel free to contact us.  Customers please Login with your email.</p>
                            <Form id="contact-form" onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="contactFormEmail">
                                    <Form.Control type="email" placeholder="name@example.com (required)" ref={emailRef}/>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="contactFormText">
                                    <Form.Control as="textarea" rows={5} size="sm" maxLength={200} placeholder="contact us" onChange={recalculate} ref={textRef}/>
                                    <Form.Label size="6px"><div className="tiny">{textCount} / 200</div></Form.Label>
                                </Form.Group>
                                <ReCAPTCHA sitekey={recaptcha_site_key} ref={captchaRef}/>
                                <SubmitMessage/>
                                <button>Submit</button>
                            </Form>
                        </div>
                    </Col>
                    <Col lg={7} sm={0} xs={0} className='d-lg-block d-md-block d-sm-none d-xs-none'><Chart/></Col>
                </Row>
                <Row>
                    <Col><div><p style={{textAlign:'right', fontSize:'12px'}}>© 2024 CodingMachine, Inc. All Rights Reserved</p></div></Col>
                </Row>
            </Container>
        </div>
        
    )

}
 