import React, { PureComponent } from 'react';
import {
  ResponsiveContainer, PieChart, Pie, 
} from 'recharts';
// serverless?
//
//
// search !!!
//
//
//
const data01 = [
  { name: 'Development', value: 100 }, 
  { name: 'Consulting', value: 115 },
  { name: 'Cloud', value: 100 },
  { name: 'Payment', value: 110 },
  { name: 'AI / ML / DL', value: 130 },
  { name: 'BI / Admin', value: 110 },
  { name: 'Subcontracts / Systems Integration', value: 535 },
];
const data02 = [
  { name: 'web', value: 30 },
  { name: 'mobile', value: 30 },
  { name: 'custom', value: 40 },
  { name: 'pilot', value: 30 },
  { name: 'POC', value: 30 },
  { name: 'IT Advisory', value: 55 },
  { name: 'Azure', value: 30 },
  { name: 'AWS', value: 45 },
  { name: 'IBM', value: 25 },
  { name: 'Stripe', value: 50 },
  { name: 'PayPal', value: 30 },
  { name: 'Avalara', value: 30 },
  { name: 'LLM', value: 30 },
  { name: 'Supervised', value: 40 },
  { name: 'Vision', value: 30 },
  { name: 'Analytics', value: 30 },
  { name: 'Segment', value: 25 },
  { name: 'Retool', value: 25 },
  { name: 'Mixpanel', value: 30 },
  { name: 'Appsmith', value: 30 },
  { name: 'java', value: 40 },
  { name: 'python', value: 35 },
  { name: 'javascript', value: 50 },
  { name: 'React', value: 40 },
  { name: 'Node', value: 40 },
  { name: 'Flutter', value: 40 },
  { name: 'Microservice', value: 65 },
  { name: 'Web Service', value: 60 },
  { name: 'REST', value: 30 },
  { name: 'IoT', value: 20 },
  { name: 'Search', value: 25 },
  { name: 'Security', value: 25 },
  { name: 'Container', value: 30 },
  { name: 'Serverless', value: 40 },
];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.60;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    // <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
    <text x={x} y={y} fill="#490297" textAnchor="middle" dominantBaseline="central">{data01[index].name}</text>
  );
};

const renderCustomizedOuterLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index,
}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    // <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
    <text x={x} y={y} fill="#800040" textAnchor="middle" dominantBaseline="central">{data02[index].name}</text>
  );
};

export default class Chart extends PureComponent {

  render() {
    return (
      <div style={{margin:'15px', width:'100%', height:'100%'}}>
        <ResponsiveContainer width="100%" height={700}>
          <PieChart>
            <Pie label={renderCustomizedLabel} data={data01} dataKey="value" cx={300} cy={300} outerRadius={200} fill="#AAAAAA" labelLine={false}/>
            <Pie label={renderCustomizedOuterLabel} data={data02} dataKey="value" cx={300} cy={300} innerRadius={220} outerRadius={290} fill="#B4B4B4" labelLine={false}/>
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}