import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Landing from './Landing'
import Ack from './Ack'
import Stores from './Stores'

export default function Main () {

    return (
        <Router>
            <Routes>
                <Route path='/' element={<Landing/>}/>
                <Route path='/Landing' element={<Landing/>}/>
                <Route path='/Ack' element={<Ack/>}/>
                <Route path='/Stores' element={<Stores/>}/>
            </Routes>
        </Router>
    )

}
 